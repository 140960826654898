<template>
    <div id="container" class="md-layout">
        <h1 class="md-layout-item md-size-80 md-small-size-90">Lizenzen verwendeter Software</h1>


        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-content>
                Das Frontend von PAW baut auf freier Software auf. Die Basis bildet hierbei "Vue.js". Im Folgenden finden sich die
                verwendeten Kompenenten inklusive ihrer jeweiligen Lizenztexte.
                Falls diese Projekte eigene Abhängigkeiten haben weisen sie diese meist auf ihrer Informationsseite aus.
            </md-card-content>
        </md-card>

        <md-card v-for="module in modules" v-bind:key="module" class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    {{ getName(module) }}
                </div>
            </md-card-header>
            <md-card-content>
                Beschreibung: {{ getDescription(module) }}
                <br>
                Autor/Autorengruppe: {{ getAuthor(module) }}
                <br>
                Version: {{ getVersion(module) }}
                <br>
                Projektadresse: <a v-bind:href="getUrl(module)"> {{ getUrl(module) }} </a>
                <br>
                Lizenz: {{ getLicense(module) }}
                <br><br>
                Lizenztext:
                <br>
                <code>{{ getLicenseText(module) }}</code>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
const packages = require("../../package.json")

export default {
    name: 'All_Licences',
    props: {},
    data: () => ({
        modules: [],
        /*modules: [
            "vue", "vue-cookies", "dateformat", "sass-loader", "vue-loader", "vue-material", "vue-router",
            "vue-web-cam", "vuelidate", "vuetify", "vuex", "postcss", "vue-slider-component"
        ]*/
    }),
    mounted() {
        const dependencies = packages.dependencies
        for(let dependency in dependencies){
            this.modules.push(dependency)
        }
    },
    components: {},
    methods: {
        getLicenseText(codename) {
            try {
                return require("raw-loader!../../node_modules/" + codename + "/LICENSE").default
            } catch (e) {
                try {
                    return require("raw-loader!../../node_modules/" + codename + "/LICENSE.md").default
                } catch (e) {
                    try {
                        return require("raw-loader!../../node_modules/" + codename + "/LICENSE.txt").default
                    } catch (e) {
                        return "Fehler aufgetreten beim Laden des Lizenztextes"
                    }
                }
            }
        },
        getVersion(codename) {
            return require("../../node_modules/" + codename + "/package.json").version
        },
        getUrl(codename) {
            return require("../../node_modules/" + codename + "/package.json").homepage
        },
        getLicense(codename) {
            return require("../../node_modules/" + codename + "/package.json").license
        },
        getName(codename) {
            return require("../../node_modules/" + codename + "/package.json").name
        },
        getDescription(codename) {
            return require("../../node_modules/" + codename + "/package.json").description
        },
        getAuthor(codename) {
            let author = require("../../node_modules/" + codename + "/package.json").author
            if(!author){
                return "kein Autor angegeben"
            }
            let ret = author.name
            ret = author.email || author.url ? ret + " (" : ret;
            ret = author.email ? ret + author.email : ret;
            ret = author.email && author.url ? ret + " / " : ret;
            ret = author.url ? ret + author.url : ret;
            ret = author.email || author.url ? ret + ")" : ret;
            return ret
        }
    }
}
</script>

<style lang="scss" scoped>
#container {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
    align-content: center;
    text-align: center;
}

.md-card {
    text-align: left;
    width: 980px;
    max-width: 90%;
    margin: 10px auto 10px;
    display: inline-block;
    vertical-align: top;
}

h1 {
    text-align: center;
    margin: 10px auto 10px;
}
</style>
